import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="arthrosen-rheuma" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Arthrose / Rheuma`}</h1>
      <h3 {...{
        "id": "arthrose--rheuma"
      }}>{`Arthrose / Rheuma`}</h3>
      <p>{`Die Ursachen / Auslöser für rheumatische Erkrankungen können sehr vielfältig sein. Meist führt eine Summierung von Belastungsfaktoren zum Ausbruch: Erreger (Bakterien, Viren), autoimmunologische Erkrankungen (Abwehrreaktionen gegen eigene Körperzellen), Ernährung (Übersäuerung, Purine…), Fehl- und Überlastungen von Gelenken und Wirbelsäule, Vitalstoffmängel – ja selbst Stress führt über vegetative (nervliche) Fehlsteuerung zu Muskel-Verspannungen, -Verhärtungen und nachfolgend zu Rückenbeschwerden.`}</p>
      <h3 {...{
        "id": "mit-schmerzmitteln-allein-ist-es-deshalb-nicht-getan"
      }}>{`Mit Schmerzmitteln allein ist es deshalb nicht getan:`}</h3>
      <p>{`Entzündliche und degenerative Gelenkerkrankungen – spez. die Wirbelsäulenbeschwerden – erfordern breit gefächerte Therapieansätze: Ernährungsberatung, `}<a parentName="p" {...{
          "href": "/beschwerdebilder/entgiftung"
        }}>{`Entgiftung`}</a>{`, angemessene `}<a parentName="p" {...{
          "href": "/therapien/chiropraktik"
        }}>{`Chiropraktik`}</a>{`, Massagetechniken, `}<a parentName="p" {...{
          "href": "/therapien/schroepfmassage"
        }}>{`Schröpfmassage`}</a>{`, orale Stoffwechselmittel und Injektionen / Infiltrationen zur Schmerzbehandlung.`}</p>
      <p>{`Die Behandlungsschritte müssen individuell kombiniert werden, um den Teufelskreislauf ENTZÜNDUNG – VERSCHLACKUNG – SCHMERZ – FEHLHALTUNG – MUSKELVERSPANNUNG – DURCHBLUTUNGSSTÖRUNG – SCHMERZ usw. zu durchbrechen.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      